body {
  background-color: #F6F9FC;
}

* {
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: Montserrat, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}