/* Color index
    -#B4182D red
    -#000000 black
    -#c4c4c4c
    -#F6F9FC


*/

/* General styles */
::selection {
    background: #c4c4c4;
  }

.container {
    margin: auto;
}

/* Navbar */
.navbar {
    margin: auto;
    width: 100%;
    max-width: 1200px;
    padding: 50px 0;
}

.toolbar {
    width: 80%;
    display: flex;
    margin: auto;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}   

.logo {
    width: 180px;

}

.contact {
    font-weight: 500;
    font-size: 18px;
    color: #B4182D;

}

.contact:hover {
    /* color: black; */
    
}
/* Hero */

.hero__container {
    margin: auto;
    margin-bottom: 20px;
}

.hero__modal {
    z-index: 10;
    border-radius: 8px;
    position: absolute;
    background-color: white;
    padding: 20px;
    width: 40%;
    left: -50%;
    right: -50%;
    top: 25%;
    bottom: -25;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.hero_title {
    font-size: 30px; color: black; padding: 15px 30px
}

.hero_subtext {
    font-size: 16px; padding: 0px 70px
}
.contactBtn {
    display: flex;
    justify-content: center;
    background: #B4182D;
    padding: 18px 42px;
    margin: auto;
    border: 2px solid #B4182D;
    font-size: 16px;
    color: white;
    font-weight: 600;
    border-radius: 3px;
}

.contactBtn:hover {
    /* color: black; */
    border: 2px solid #B4182D;
    background: inherit;
    transition-delay: 100ms;
    color: #B4182D;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);

}
.gallery {
    margin: auto
}

.gallery__item {
    width: 100%;
    max-width: 2400px;
    height: auto;
}

/* client imgs */

.client__parent {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
    padding: 40px 0;
}


.client__child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.client__card {

}

.client__img {
    height: 105px;
    width: auto;
}

/* Sliders */

.slide__container {
    margin: 40px auto;
    width: 60%;
}

.slide__div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.slide__div__inverted {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

}
.slide__img {
    width: 420px;
    height: auto;
    border-radius: 8px;
}

.slide__content {
    width: 50%;
    padding: 0 10px

}

.slide__title {
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 20px;
}

.slide__text {
    
}

/* Recent pictures */

.slider__parent {
    width: 60%;
    padding: 40px 0;
    margin: auto;
    
}
.slider__title {
    font-size: 24px;
}
.slider__gallery {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
}

.gallery_img {
    width: auto;
    height: 420px;
    border-radius: 3px;
    padding-Right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;

}

/* footer */

.footerDiv {
    display: flex;
    margin: auto;
    width: 60%;
    font-size: 14px;
    max-width: 1200px;
    padding: 10px 0;
    color: #c4c4c4;
    justify-content: space-between;
    flex-direction: column;
}

.trademark {
    color: grey;
    display: flex;
    justify-content: center;
}

.dividerRoundel {
    width: 6px;
    height: 6px;
    margin: 0 8px;
    background-color: #c4c4c4;
    border-radius: 50%;

}

.email {
    color: grey
}

@media (max-width: 1250px){
    .container {
        margin: auto;
    }
    
    /* Navbar */
    .navbar {
        margin: auto;
        width: 100%;
        max-width: 1200px;
        padding: 50px 0;
    }
    
    .toolbar {
        width: 80%;
        display: flex;
        margin: auto;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
    }   
    
    .logo {
        width: 180px;
    
    }
    
    .contact {
        font-weight: 500;
        font-size: 18px;
        color: #B4182D;
    
    }
    
    .contact:hover {
        /* color: black; */
        
    }
    /* Hero */
    
    .hero__container {
        margin: auto;
        margin-bottom: 20px;
    }
    
    .hero__modal {
        z-index: 10;
        border-radius: 8px;
        position: absolute;
        background-color: white;
        padding: 20px;
        width: 60%;
        left: -50%;
        right: -50%;
        top: 20%;
        padding: 20px;
        margin: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .hero_title {
        font-size: 30px; color: black; padding: 15px 0
    }
    
    .hero_title {
        font-size: 30px; color: black; padding: 15px 0
    }

    .hero_subtext {
        font-size: 16px; padding: 0px 20px
    }
    .contactBtn {
        display: flex;
        justify-content: center;
        background: #B4182D;
        padding: 18px 42px;
        margin: auto;
        border: 2px solid #B4182D;
        font-size: 16px;
        color: white;
        font-weight: 600;
        border-radius: 3px;
    }
    
    .contactBtn:hover {
        /* color: black; */
        border: 2px solid #B4182D;
        background: inherit;
        transition-delay: 100ms;
        color: #B4182D;
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    
    }
    .gallery {
        margin: auto
    }
    
    .gallery__item {
        width: 100%;
        max-width: 2400px;
        height: auto;
    }
    
    /* client imgs */
    
    .client__parent {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: auto;
        padding: 40px 0;
    }
    
    
    .client__child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .client__card {
    
    }
    
    .client__img {
        height: 70px;
        width: auto;
    }
    
    /* Sliders */
    
    .slide__container {
        margin: 40px auto;
        width: 80%;
    }
    
    .slide__div {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
    
    .slide__div__inverted {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    
    }
    .slide__img {
        width: 350px;
        height: auto;
        border-radius: 8px;
    }
    
    .slide__content {
        width: 50%;
    }
    
    .slide__title {
        font-size: 18px;
        font-weight: 600;
        padding-bottom: 20px;
    }
    
    .slide__text {
        font-size: 16px;
    }
    
    /* Recent pictures */
    
    .slider__parent {
        width: 80%;
        padding: 40px 0;
        margin: auto;
        
    }
    .slider__title {
        font-size: 24px;
    }
    .slider__gallery {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
    }
    
    .gallery_img {
        width: auto;
        height: 320px;
        border-radius: 3px;
        padding-Right: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
    
    }
    
    /* footer */
    
    .footerDiv {
        display: flex;
        margin: auto;
        width: 80%;
        font-size: 14px;
        max-width: 1200px;
        padding: 10px 0;
        color: #c4c4c4;
        justify-content: space-between;
        flex-direction: column;
    }
    
    .trademark {
        color: grey;
        display: flex;
        justify-content: center;
    }
    
    .dividerRoundel {
        width: 6px;
        height: 6px;
        margin: 0 8px;
        background-color: #c4c4c4;
        border-radius: 50%;
    
    }
    
    .email {
        color: grey
    }
}

@media (max-width: 700px){
    .gallery__item {
       display: none;
    }
    .container {
        margin: auto;
    }
    
    /* Navbar */
    .navbar {
        margin: auto;
        width: 100%;
        max-width: 1200px;
        padding: 50px 0;
    }
    
    .toolbar {
        width: 80%;
        display: flex;
        margin: auto;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    }   
    
    .logo {
        width: 180px;
        margin-bottom: 20px;
    }
    
    .contact {
        font-weight: 500;
        font-size: 18px;
        color: #B4182D;
    
    }
    
    .contact:hover {
        /* color: black; */
        
    }
    /* Hero */
    
    .hero__container {
        margin: auto;
        margin-bottom: 20px;
    }
    
    .hero__modal {
        border-radius: 8px;
        background-color: inherit;
        width: 90%;
        margin: auto;
        position: static;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .hero_title {
        font-size: 30px; color: black; 
    }

    .hero_subtext {
        font-size: 16px; padding: 0px 20px
    }
    .contactBtn {
        display: flex;
        justify-content: center;
        background: #B4182D;
        padding: 18px 42px;
        margin: auto;
        border: 2px solid #B4182D;
        font-size: 16px;
        color: white;
        font-weight: 600;
        border-radius: 3px;
    }
    
    .contactBtn:hover {
        /* color: black; */
        border: 2px solid #B4182D;
        background: inherit;
        transition-delay: 100ms;
        color: #B4182D;
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    
    }
    .gallery {
        margin: auto
    }
    
    .gallery__item {
        width: 100%;
        max-width: 2400px;
        height: auto;
    }
    
    /* client imgs */
    
    .client__parent {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: auto;
        padding: 40px 0;
    }
    
    
    .client__child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    
    .client__card {
    
    }
    
    .client__img {
        height: auto;
        max-width: 180px;
        width: 100%;
        margin: 20px 0
    }
    
    /* Sliders */
    
    .slide__container {
        margin: 40px auto;
        width: 90%;
    }
    
    .slide__div {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        flex-direction: column-reverse;
        margin-bottom: 30px;
    }
    
    .slide__div__inverted {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 30px;
        align-items: center;
        justify-content: space-between;
    
    }
    .slide__img {
        width: 300px;
        height: auto;
        border-radius: 8px;
        margin: auto;
    }
    
    .slide__content {
        width: 80%;
        margin: 20px 0;
    }
    
    .slide__title {
        font-size: 18px;
        font-weight: 600;
        padding-bottom: 10px;
    }
    
    .slide__text {
        font-size: 16px;
    }
    
    /* Recent pictures */
    
    .slider__parent {
        width: 80%;
        padding: 40px 0;
        margin: auto;
        
    }
    .slider__title {
        font-size: 24px;
    }
    .slider__gallery {
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
    }
    
    .gallery_img {
        width: auto;
        height: 240px;
        border-radius: 3px;
        padding-Right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
    
    }
    
    /* footer */
    
    .footerDiv {
        display: flex;
        margin: auto;
        width: 80%;
        font-size: 14px;
        max-width: 1200px;
        padding: 10px 0;
        color: #c4c4c4;
        justify-content: space-between;
        flex-direction: column;
    }
    
    .trademark {
        color: grey;
        display: flex;
        justify-content: center;
    }
    
    .dividerRoundel {
        width: 6px;
        height: 6px;
        margin: 0 8px;
        background-color: #c4c4c4;
        border-radius: 50%;
    
    }
    
    .email {
        color: grey
    }
}

